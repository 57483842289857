import React from "react"
import { PageProps, graphql } from "gatsby"
import styled from "@emotion/styled"
import { Carousel } from "react-bootstrap"
import Img, { FluidObject, GatsbyImageFluidProps } from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagram,
  faTelegramPlane,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

interface Props {
  slide01: {
    childImageSharp: GatsbyImageFluidProps
  }
  slide02: {
    childImageSharp: GatsbyImageFluidProps
  }
  slide03: {
    childImageSharp: GatsbyImageFluidProps
  }
  slide04: {
    childImageSharp: GatsbyImageFluidProps
  }
  slide05: {
    childImageSharp: GatsbyImageFluidProps
  }
  slide06: {
    childImageSharp: GatsbyImageFluidProps
  }
  slide07: {
    childImageSharp: GatsbyImageFluidProps
  }
}

type ButtonProps = {
  background?: string
  color?: string
}

const Title = styled.h1`
  margin-top: 2rem;
`

const Button = styled.a<ButtonProps>`
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  margin-bottom: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    background: ${({ background }) => background};
    color: ${({ color }) => color};
    opacity: 0.6;
  }
`

const ButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 1rem;
  vertical-align: middle;
`

const IndexPage: React.FC<PageProps<Props>> = ({ data }) => (
  <Layout>
    <SEO title="Monomy Art" lang="ru" />
    <Title>Портрет на холсте по фотографии</Title>
    <p>
      Уникальный подарок со смыслом, который оставит незабываемые эмоции на всю
      жизнь!
    </p>
    <section className="mt-5">
      <Button
        className="btn btn-lg btn-block"
        href="https://wa.me/79185765692?text=Здравствуйте! Уточните, пожалуйста, цены на размеры портретов"
        role="button"
        color="#fff"
        background="#55cd6c"
      >
        <ButtonIcon icon={faWhatsapp} size="2x" />
        Узнать стоимость в WhatsApp
      </Button>
      <Button
        className="btn  btn-lg btn-block"
        href="https://t.me/monomyart"
        role="button"
        color="#fff"
        background="#61a8de"
      >
        <ButtonIcon icon={faTelegramPlane} size="2x" />
        Узнать стоимость в Telegram
      </Button>
      <Button
        className="btn btn-lg btn-block"
        href="https://www.instagram.com/monomy.art/"
        role="button"
        color="#fff"
        background="#f00075"
      >
        <ButtonIcon icon={faInstagram} size="2x" />
        Написать вопрос в Instagram
      </Button>
      <Button
        className="btn btn-primary btn-lg btn-block"
        href="tel:+79185765692"
        role="button"
      >
        Позвонить
      </Button>
    </section>

    <Carousel className="mt-5">
      <Carousel.Item>
        <Img
          fluid={data.slide01.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №1"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.slide02.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №2"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.slide03.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №3"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.slide04.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №3"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.slide05.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №3"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.slide06.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №3"
        />
      </Carousel.Item>
      <Carousel.Item>
        <Img
          fluid={data.slide07.childImageSharp.fluid}
          className="d-block w-100"
          alt="Отзыв №3"
        />
      </Carousel.Item>
    </Carousel>

    <ul className="mt-5">
      <li>
        100% <b>ручная работа</b> профессионального художника.
      </li>
      <li>
        Используем только <b>натуральные</b> и <b>качественные</b> материалы.
      </li>
      <li>
        Готовая картина <b>покрывается лаком</b>, что обеспечивает её{" "}
        <b>долговечность</b>.
      </li>
      <li>
        <b>Доставляем бесплатно</b> по всему миру!
      </li>
    </ul>

    <footer className="text-center mt-5 mb-2">
      Monomy Art — портреты с любовью 💕
    </footer>
  </Layout>
)

export const query = graphql`
  query {
    slide01: file(relativePath: { eq: "slide1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slide02: file(relativePath: { eq: "slide2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slide03: file(relativePath: { eq: "slide3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slide04: file(relativePath: { eq: "slide4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slide05: file(relativePath: { eq: "slide5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slide06: file(relativePath: { eq: "slide6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    slide07: file(relativePath: { eq: "slide7.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
